.contact__container{
  margin-top: 100px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3{ @include mobile-title;
    padding-top: 0px;
    color: $hot-dark-color;
  }


  .contact__form-shape-container{
    width: 100%;
   
  .contact__shape{
    display: none;
  }
  .contact__form{
    display: flex;
    flex-direction: column;
    
    max-width: 400px;
    

    label{
      @include mobile-text;
      padding-top: 20px;
      padding-bottom: 5px;
      text-align: start;
      color: grey;
      span {
        color: $hot-dark-color;
        font-size: 18px;
      
        
      }
    }
    .contact__data-message{
      font-size: 15px;
      .contact__checkbox{
      margin-right: 5px;
      
      }
      a{
        text-decoration: underline;
      }
    }


    .error{
      @include mobile-text;
      font-size: 15px;
      padding-top: 5px;
      color: $hot-dark-color;
    }

    textarea, input{
      @include mobile-text;
      font-size: 15px;
      padding: 5px;
    }
    .contact__input-bad{
      border-color: $hot-dark-color;
      border-style: solid;
      border-width: unset;
 
    }
    .contact__input-good {
      border-color: $cold-dark-color;
      border-style: solid;
      border-width: unset;
    } 


    .contact__message{
      height: 150px;
    }
    button {
      @include mobile-button;
      margin-top: 30px;
      cursor: pointer;
    }
    button:disabled{
      background: grey !important;
      pointer-events: none;
    }

    p{
      @include mobile-text;
      padding-top: 20px;
      padding-bottom: 5px;
      text-align: start;
      color:$hot-dark-color;
      span{
        color: grey;
      }
    }
  }
  }
}

@media (min-width: 768px) {
  .contact__container{
    margin-top: 70px;
    margin-bottom: 100px;
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    h3{ @include tablet-title;
      padding-top: 0px;
      color: $hot-dark-color;
    }

    .contact__form-shape-container{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    .contact__shape{
      display: block;
      background-image: url("../../images/shape.png");
      background-size: cover;
      background-repeat: no-repeat;
      height: 500px;
      width: 400px;
      margin-left: 100px;
 
    
    }

    .contact__form{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
  
      label{
        @include tablet-text;
        padding-top: 20px;
        padding-bottom: 5px;
        text-align: start;
        color: grey;
      }
      .contact__data-message{
        font-size: 15px;
        .contact__checkbox{
        margin-right: 5px;
        
        }
        a{
          text-decoration: underline;
        }
      }
      textarea, input{
        @include tablet-text;
        font-size: 15px;
        padding: 5px;
      }
      
      .contact__message{
        height: 150px;
      }
      button {
        @include tablet-button;
        margin-top: 30px;
      }
  
      p{
        @include tablet-text;
        padding-top: 20px;
        padding-bottom: 5px;
        text-align: start;
        color:$hot-dark-color;
        span{
          color: grey;
        }
      }
  
    }
  }
  }
}

@media (min-width: 1200px) {
  .contact__container{
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 55px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  
    .contact__form-shape-container {
      .contact__shape{
       display: block;
       background-image: url("../../images/shape.png");
       background-size: contain;
       background-repeat: no-repeat;
       height: 500px;
       width: 400px;
       margin-left: 0px;
       margin-right: 50px;
  
     
     }
   }

    h3{ @include pc-title;
      padding-top: 0px;
      padding-bottom: 20px;
      color: $hot-dark-color;
    }
    .contact__form{
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 400px;
  
      label{
        @include tablet-text;
        padding-top: 20px;
        padding-bottom: 5px;
        text-align: start;
        color: grey;
      }
  
      textarea, input{
        @include tablet-text;
        font-size: 15px;
        padding: 5px;
      }
      .contact__message{
        height: 150px;
      }
      button {
        @include tablet-button;
        margin-top: 30px;
      }
  
      p{
        @include tablet-text;
        padding-top: 20px;
        padding-bottom: 5px;
        text-align: start;
        color:$hot-dark-color;
        span{
          color: grey;
        }
      }

    }
  }
}


@media (min-width: 1500px) {

}