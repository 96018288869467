.hero__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin-top: 69px;

        .hero__background-image{
        width: 100%;
        height: 500px;        
        background-image: url("../../images/herohome.jpg");
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;

            .hero__text-container-pc {
                display: none;
            }
        }
       
    .hero__text-container-mobile {
        
        h2 {
            @include mobile-title;
            color: $hot-dark-color;
            margin-top: 2px;
        }

        p { 
            @include mobile-text;           
        }

        .button{
            @include mobile-button;
            margin-top: 14px;
        }
    }

}

@media (min-width: 768px) {

    .hero__container {
        height: 100vh;
    
            .hero__background-image{
            width: 100vw;
            height: 100vh;        background-image: url("../../images/herohome.jpg");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 70px;
            padding-right: 30px;
            padding-bottom: 30px;
    
                .hero__text-container-pc {
                    display: block;
                    width: 380px;
                    height: 369px;
                    background-color: white;
                    opacity: 0.9;
                    padding: 30px;
                    margin:0px;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16); 
                    

                    .hero__title {
                        font-family:  $title-font;
                        font-size: 30px;
                        font-weight: 500;
                        line-height: 1.3;
                        color: $hot-dark-color;
                        margin-top: 0px;
                    }
        
                
                p { 
                    @include tablet-text;
                     margin: 5px;
                }
        
                .button{
                    @include tablet-button;
                    margin-top: 25px;
                    cursor: pointer;
                }
            }
        }
    }
    
    .hero__text-container-mobile {
            display: none;       
  }
}






@media (min-width: 1200px) {

    .hero__container {

        height: 100vh;
        margin-top: 0px;
    
            .hero__background-image{
            width: 100vw;
            height: 100vh;        background-image: url("../../images/herohome.jpg");
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 70px;
    
            .hero__text-container-pc {
                display: block;
                width: 480px;
                height: 400px;
                background-color: white;
                opacity: 0.9;
                padding: 30px;
                margin:0px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                

                .hero__title {
                    font-family:  $title-font;
                    font-size: 30px;
                    font-weight: 500;
                    line-height: 1.3;
                    color: $hot-dark-color;
                }
        
                
                p { 
                    @include pc-text;
                    margin: 5px;
                    
                }
        
                .button{
                    @include pc-button;
                    margin-top: 25px;
                }
            }
            }
            }
           
        
    
        .hero__text-container-mobile {
            display: none;
           
  }
}


@media (min-width: 1400px) {

    .hero__container {


            .hero__background-image{

            padding: 140px;
    
            
            }
        }
}