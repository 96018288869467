.App {
  display: flex;
  flex-direction: column;
}

.container {
  padding: 35px 25px;
}

.bold-title{
  font-weight: 600;
          }






@media (min-width: 768px) {
  .container {
    padding: 80px 60px;
  }
}

@media (min-width: 1200px) {
  .container {
    padding: 120px 80px;
  }
}


@media (min-width: 1500px) {
  .container {
    padding: 120px 80px;
  }
}