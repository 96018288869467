.card__container{
    background-color: white;
    border-radius: 38px 10px 10px 10px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);   
    margin: 20px 0;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    .card__header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 70px;


        .card-details__close-icon{
            display: none;
            cursor: pointer;
        }

        .card__circle{
            background-color: $hot-dark-color;
            border-radius: 100px;
            height: 70px;
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                color: $inverted-text-color;
                font-size: 30px;

            }
        }

        h4 {
            @include mobile-text;
            font-size: 15px;
            letter-spacing: 1px;
            color: $card-title;
            line-height: 1.5;
            font-weight: 900;
            margin: 0px;
            text-align: end;
            margin-right: 12px;
            margin-top: 10px;
            text-transform: uppercase;
            padding: 12px;

        }
    }

    .card__text-container{
        display: flex;
        flex-direction: column;
        padding: 20px;
        

       .card__text{
            @include mobile-text;
            margin-top: 10px;
       } 
         .price{
            @include mobile-text; 
                color: $card-title;
                text-align: right;
            }

       .read-more{
            @include mobile-text;
            font-weight: 900;
            color: $hot-dark-color;
            text-align: end;
            cursor: pointer;
       }

       .read-less{
           display: none;
           cursor: pointer;
       }
    }
    .card-details__text-container{
        display: none;
    }
}

@media (min-width: 768px) {
    .card__container{
        background-color: #fff;
        border-radius: 38px 20px 20px 20px;
        box-shadow: 0 20px 60px rgba(0,0,0,.16);
        margin: 20px;
        width: 225px;
        height: 340px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

    }
}