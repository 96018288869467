$title-font: 'Poppins', sans-serif;
$text-font: 'Lato', sans-serif;


$text-color: #292F36;
$card-title: #4e5153;
$dark-color: #2c2220;
$inverted-text-color: white;
$hot-dark-color: #FB8F67;
$hot-light-color: #FFC2B4;
$cold-dark-color: #40C9C0;
$cold-light-color: #80EFED;
