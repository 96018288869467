.footer {
  
    background-color: $cold-dark-color;
    margin: 0px;

  .footer__logo-rrss-container{
      border-bottom: solid 1px $inverted-text-color;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 15px;  
      padding-bottom: 15px;

      .footer__logo-container{
        height: 100%;
        @include mobile-logo;
        


      .footer__logo-image-container{
        padding: 0px;
        height: 100%;
        @media screen and (max-width: 768px){
          padding-bottom: 15px;
        }
        cursor: pointer;
          img {
              height: 100%;
          }
      }

      .footer__logo-title{
        color: $inverted-text-color;          
        font-size: 19px;
        font-weight: 200;
        cursor: pointer;

      }
  }

  .footer-rrss {
    display: flex;
    flex-direction: row;
    
      li {
      padding: 10px;
      padding-top: 0px;

        img {
          height: 30px;
        }
      }
  }
}


.footer__workers{
  font-family: $text-font;
  font-size: 14px;
  line-height: 1;  
  color: $inverted-text-color;
  text-align: center;
  display: block;
  .footer__data-list{
    padding: 15px 30px;
    border-bottom: solid 1px white;
  }
  .footer__workers-list{
    padding: 15px 30px;
  }
  }
}


@media (min-width: 768px) {

  .footer {
    .footer__logo-rrss-container{
      padding-right: 80px;
      padding-left: 80px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .footer__workers{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .footer__data-list{
        padding-left: 80px;
        border-bottom: solid 1px white;
        text-align: start;
      }
      .footer__workers-list{
        padding-right: 80px;
        text-align: end;
      }
      }
    }
  


}





@media (min-width: 1200px) {
  .footer{

    padding-left: 100px;
    padding-right: 100px;


}
}