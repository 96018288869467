@mixin mobile-title {
    font-family:  $title-font;
    font-size: 23px;
    font-weight: 500;
    line-height: 1.5;
}

@mixin tablet-title {
    font-family:  $title-font;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.5;

}

@mixin pc-title {
    font-family:  $title-font;
    font-size: 36px;
    font-weight: 500;
    line-height: 1.5;
}

@mixin mobile-text {
    font-family: $text-font;
    font-size: 18px;
    color: $text-color;
    line-height: 1.3;
}

@mixin tablet-text {
    font-family: $text-font;
    font-size: 20px;
    color: $text-color;
    line-height: 1.3;
}

@mixin pc-text {
    font-family: $text-font;
    font-size: 22px;
    color: $text-color;
    line-height: 1.5;
}



@mixin mobile-logo{
    font-family: $title-font;
    font-size: 20px;
    display: flex;
    flex-direction: row;
    height: 80px;
    font-weight:200;

}


@mixin mobile-button {
    background-color: $hot-dark-color;
    padding: 10px;
    border: none;
    border-radius: 1px;
    border-color: $hot-dark-color;
    color: $inverted-text-color;
    font-family: $text-font;
    font-weight:900;
    font-size: 18px;

}

@mixin tablet-button {
    background-color: $hot-dark-color;
    padding: 10px;
    border: none;
    border-radius: 1px;
    border-color: $hot-dark-color;
    color: $inverted-text-color;
    font-family: $text-font;
    font-weight:900;
    font-size: 22px;
    max-width: 180px;
}

@mixin pc-button {
    background-color: $hot-dark-color;
    padding: 10px;
    border: none;
    border-radius: 1px;
    border-color: $hot-dark-color;
    color: $inverted-text-color;
    font-family: $text-font;
    font-weight:900;
    font-size: 24px;
    max-width: 200px;
}
