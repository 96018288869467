.header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 1;
    box-shadow:rgba(0, 0, 0, 0.2) 0px 25px 50px 0px;
    background-color: white;
    margin: 0px;

    .header__nav-tablet{
      display: none;
    }


    .header__logo-container{
      height: 120px;
      @include mobile-logo;
      padding-left: 20px;
      // padding-top: 5px;

      .header__logo-image-container{
          // padding: 10px;
          // height: 50px;
          img {
              // height: 50px;
              height: 100%;
          }
      }

      .header__logo-title{
          color: $cold-dark-color;
          font-size: 20px;
          font-weight:200;    
      }
    }

    li:hover {
      color: $cold-dark-color;
      transition: color 0.3s ease;
  }

    .header__hamburger-close{
        @include mobile-text;
        
        .header__hamburger-container{
          padding: 25px;
          padding-top: 30px;
        }
        
        span
        {
          display: block;
          width: 33px;
          height: 4px;
          margin-bottom: 7px;
          position: relative;
          
          background: $cold-dark-color;
          border-radius: 3px;
          
          z-index: 1;
          
          transform-origin: 4px 0px;
          
          transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                      background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                      opacity 0.55s ease;
        }

        .header__menu-list  {
          display: none;
        }
    }

    .header__hamburger-open{
      @include mobile-text;

      
      .header__hamburger-container{
        padding: 25px;
        padding-top: 30px;      }

      span
        {
          display: block;
          width: 33px;
          height: 4px;
          margin-bottom: 7px;
          position: relative;
          
          background: $cold-dark-color;
          border-radius: 3px;
          
          z-index: 1;
          
          transform-origin: 4px 0px;
          
          transition: transform 0.3s cubic-bezier(0.77,0.2,0.05,1.0),
                      background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                      opacity 0.55s ease;
        }

        .header__first-bar
      {
        transform-origin: 0% 0%;
        transform:  rotate(45deg)
      }
      
      .header__third-bar
      {
        transform-origin: 0% 100%;
        transform: rotate(-45deg)
      }

      .header__second-bar
      {
        opacity: 0;
        transform: rotate(0deg) scale(0.2, 0.2);
      }
      


      .header__menu-list {
        display: block;
        transition: 0.3s; 
        background-color: $inverted-text-color; 
        position: fixed;
        width: 300px;
        margin: -100px 0 0 150px;
        margin-top: 0px;
        padding: 20px;
        transform: translate(-100%, 0);
        box-shadow: -9px 8px 10px rgba(0, 0, 0, 0.16);
        border-top: 1px solid #f1f1f1;
        li {
          padding: 10px 0;
          font-size: 22px;

        }
      }





  }
}



@media (min-width: 768px) {

    .header {
      height: 100px;
      .header__nav-tablet{
        display:block;

          li {
            @include tablet-text; 
            font-size: 18px;
            color: $cold-dark-color;
            font-weight: 400;
            padding: 10px;

          }

          li:hover {
            color: $hot-dark-color;
            transition: color 0.3s ease;
          }

          .header__menu-list {
            display: flex;
            flex-direction: row;
            margin: 0;
            box-shadow: 0;
            padding-right: 20px;
             }

            }

    .header__hamburger-container{
       display: none;    
      }
  }







@media (min-width: 1200px) {
    .header{
      padding-left: 100px;
      padding-right: 100px;


      li {
        color: $cold-dark-color;
        font-size: 20px;
        padding: 20px;
      }
    }
  }
}