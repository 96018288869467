.main__container {
    width: 100%;
    padding-bottom: 25px;
    h3 {
        @include mobile-title;
        background-color: $hot-light-color;
       
        margin: 0px;
        padding-top: 50px;
        padding-bottom: 0px;
        color: $dark-color;
    }

    .main__lists-container{

        li {
            @include mobile-text;
            padding-top: 6px;
            padding-bottom: 6px;
            line-height: 1.5;

            &::before {
                content: '·';
                margin-left: -11px;
                margin-right: 5px;
            }
        }

        .main__first-list {
            background-color: $hot-light-color;
            padding: 30px;
            padding-bottom: 50px;

            h3{ 
                padding: 30px;
            }
        }

        .main__second-list-container{
            background-color: white;
            padding: 30px;

            h3 {
                color: $cold-dark-color;
                padding-bottom: 30px;
                padding-left: 0px;
                padding-top: 20px;
                margin: 0px;
                background-color: white;
            }
        }
    }
}



@media (min-width: 768px) {
    .main__container {
        padding-bottom: 0px;
        h3 {
            @include tablet-title;
            background-color: $hot-light-color;
            padding: 60px 80px;
            margin: 0px;
            padding-bottom: 30px;

        }
    
        .main__lists-container{

            li {
                @include tablet-text;
                padding-top: 7px;
                padding-bottom: 7px;
                line-height: 1.5;
            }

            .main__first-list {
                padding-left: 80px;
                padding-bottom: 50px;
                padding-top: 0px;
                padding-right: 60px;
               
            }
            .main__second-list-container{
               
                padding: 60px;
                    
                h3 {
                    padding-bottom: 40px;
                    padding-left: 10px;
                    padding-top: 0px;
                    margin: 0px;
                }    

                .main__second-list {
                    padding: 0;
                    margin: 0;
                    padding-left: 10px;
                }
            }
        }
    
      
    }
}

@media (min-width: 1200px) {
    .main__container {
        background-color: $hot-light-color;  
        min-height: 600px;
    
        h3 {
            @include tablet-title;
            background-color: $hot-light-color;
            padding-top: 80px;
            padding-left: 120px;
            padding-bottom: 50px;

        }
    
        .main__lists-container{
            display: flex;
            flex-direction: row;
            justify-content: unset;
            padding-left: 40px;

            li {
                @include tablet-text;
            }

            .main__first-list {
                padding-left: 80px;
                padding-bottom: 80px;
                padding-top: 0px;
                padding-right: 60px;
                margin-top: 15px;
               
            }

            .main__second-list-container{
                border-radius: 5px;
                box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);
                padding: 50px;
                max-width: 70vw;
                margin-bottom: 100px;
                margin-right: 70px;

            }
               
                
    
                h3 {
                    color: $cold-dark-color;
                    background-color: white;
                    margin: 0px;
                    padding-right: 0px;


                    padding-bottom: 40px;
                    padding-left: 10px;
                    padding-top: 0px;
                    
                }    
            }
        }
    
    
    }

