.card-details__container{
    background-color: white;
    border-radius: 38px 10px 10px 10px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);
    margin: 20px 0;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .card__header{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        height: 70px;

        .card-details__close-icon{
            display: none;
            cursor: pointer;
        }

        .card__circle{
            background-color: $hot-dark-color;
            border-radius: 100px;
            height: 70px;
            width: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            i{
                color: $inverted-text-color;
                font-size: 30px;

            }
        }

        h4 {
            @include mobile-text;
            font-size: 15px;
            letter-spacing: 1px;
            color: $card-title;
            line-height: 1.5;
            font-weight: 900;
            margin: 0px;
            text-align: end;
            margin-right: 12px;
            margin-top: 10px;
            text-transform: uppercase;
            padding: 12px;
        }
    }
    .card__text-container{
        display: flex;
        flex-direction: column;
        padding: 20px;

       .card__text{
            @include mobile-text;
            margin-top: 10px;
        }
        
        .price{
            @include mobile-text; 
                color: $card-title;
                text-align: left;
                margin-bottom: 0px;
            }

       .read-more{
            display: none;
       }

       .read-less{
        @include mobile-text;
        font-weight: 900;
        color: $hot-dark-color;
        text-align: end;
        }
    }

    .card-details__text-container{
        display: block;
        @include mobile-text;
        padding-top: 0px;

        h5{
            @include mobile-text;
            font-weight: 900;
            margin: 0px;
            text-align: end;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .card-details__mobile-no{display: none;}



        .read-less{
            @include mobile-text;
            font-weight: 900;
            color: $hot-dark-color;
            text-align: end;
            
           }
    }
}

@media (min-width: 768px) {
.card-details__container{
    position: fixed;
    z-index: 99;
    top: 100px;
    left: 50px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);  
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: 90vw;
    min-width: 90vw;
    min-height: 50vh;

    

    .card__header{
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        justify-content: center;
        align-items: center;
        height: auto;

  .card-details__close-icon{
      display: block;
      font-size: 25px;
      color: $text-color;
      align-self: flex-end;
      margin-right: 30px;
  }
  .card__circle{
    background-color: $hot-dark-color;
    border-radius: 100px;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    
    
        i{
            color: $inverted-text-color;
            font-size: 30px;

        }
    }
    h4{
        margin: 0px;
        @include tablet-title;
        text-align: center;
        padding: 20px 0 0 0;  
        color: $hot-dark-color;
        text-transform: none;
        .card__breakline{
            display: none;
        }
    }
    }

    .card__text-container{
        display: none;

    }

    .card-details__text-container{
        .card-details__mobile-no{
            display: block;
        } 
        
        .read-less{
            display: none;
        }

        display: block;
        @include tablet-text;
        text-align: start;
        margin-left: 30px;
        margin-top: 0px;
        padding-top: 0px;
        max-width: 1100px;

        h5{
            @include tablet-title;
            margin: 0px;
            padding: 10px;
            text-align: center;
            color: $hot-dark-color
        }


    }


    p{
        display: block;
        @include tablet-text;
        .telephone{
            font-weight: 900;
        }
    }
    h5 {
        @include tablet-title;
        color: $hot-dark-color;
        
    }
    .button{
        @include tablet-button;
        margin-bottom: 20px;
        margin-top: 20px;
        max-width: 150px;
        cursor: pointer;
    }
}
}

@media (min-width: 1200px) {
    .card-details__container{
        left: 70px;
        max-width: 90vw;
        min-width: 90vw;
        min-height: 50vh;
    
        .card__header{
    
      .card-details__close-icon{
          font-size: 35px;
      }
      .card__circle{
        height: 90px;
        width: 90px;

            i{
                color: $inverted-text-color;
                font-size: 40px;
            }
        }
        h4{
            @include pc-title;
            padding: 20px 0 0 0;
        }
        }

        .card-details__text-container{
    
            display: block;
            @include tablet-text;
            text-align: start;
            margin-left: 80px;
            margin-top: 0px;
            padding-top: 0px;
            max-width: 1300px;
    
            h5{
                @include tablet-title;
                margin: 0px;
                padding: 10px;
                text-align: center;
                color: $hot-dark-color
            }
    
    
        }
    
    
        p{
            display: block;
            @include tablet-text;
            .telephone{
                font-weight: 900;
            }
        }
        h5 {
            @include tablet-title;
            color: $hot-dark-color;
            
        }
        .button{
            @include tablet-button;
            margin-bottom: 25px;
            margin-top: 25px;
            max-width: 150px;
        }
    }
    }
