.card-list__introduction{
    @include mobile-title;
    color: $hot-dark-color;
    margin: 0px;
    padding: 55px 30px;
    background: #f9f9f9;
}


.card-list__title{
    @include mobile-title;
    color: $cold-dark-color;
    mix-blend-mode: multiply; 
    padding: 30px;
    padding-top: 60px;
    margin: 0px;
}

.card-list__container{
    background-image: url("../../images/fondo-tipos2.svg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.card-list__pc-background-container{
    background-image:none;
}

.card-list__pc-background-container__another-page{
    background-image:none;
    margin-top: 120px;
}


@media (min-width: 768px) {
    .card-list__introduction{
         @include tablet-title;
        color: $hot-dark-color;
        padding: 80px 60px;

    
    }
    
    .card-list__title{
         @include tablet-title;
        color: $cold-dark-color; 
        text-align: center;
    }
    .card-list__container{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
      
    }

  
}


@media (min-width: 1200px) {
    .card-list__introduction{
    padding: 20px;
    margin: 0px;
    padding-top: 80px;
    padding-right: 180px;
    padding-left: 100px;
    padding-bottom: 40px;
    background-color: white;
    }
        }


@media (min-width: 1500px) {
    .card-list__introduction{
        padding: 20px;
        margin: 0px;
        padding-top: 80px;
        padding-right: 180px;
        padding-left: 100px;
        padding-bottom: 80px;
        background-color: white;
        }
    
    .card-list__title{
         @include pc-title;
        color: $cold-dark-color; 
        text-align: center;
        mix-blend-mode: multiply;
        padding-top: 20px;
    }
    .card-list__container{
        background-image:none;
    }

    .card-list__pc-background-container{
 
        background-image: url("../../images/fondo-tipos2.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 0px 20px;
        }

    .card-list__pc-background-container__another-page{
        width: 100%;
    background-image: url("../../images/fondo-tipos2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 0px 28px;
    margin-top: 180px;
    margin-bottom: 100px;
    }
}