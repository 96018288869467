.about-me-home__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    padding-top: 70px;
    padding-bottom: 70px;

    .about-me-home__image{
       
        background-image: url("../../images/portrait-square.jpg");
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;

        width: 200px;
        height: 200px;
       
        border: 4px solid $cold-dark-color;
        border-radius: 150px;
    }
    .about-me-home__text-container{
    
        h2{
            @include mobile-title;
            color: $cold-dark-color;
            padding-top: 40px;
            padding-bottom: 20px;

        }
        p{
            @include mobile-text;
            padding-top: 5px;
        }
        .read-more{
            @include mobile-text;
            color: $hot-dark-color;
            font-weight: 900;
            padding-top: 30px;
            cursor: pointer;
        }
    }
}


@media (min-width: 768px) {
    .about-me-home__container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 80px 60px;
        padding-top: 50px;
        .about-me-home__image{
           
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
    
            width: 220px;
            height: 220px;
           
            border: 4px solid $cold-dark-color;
            border-radius: 150px;
        }
        .about-me-home__text-container{
            width: calc(100vw - 400px);
            max-width: 1000px;
            h2{
                @include tablet-title;
                color: $cold-dark-color;
                margin: 0px;
                padding: 20px 0;
    
            }
            p{
                @include tablet-text;
    
            }
            .read-more{
                @include tablet-text;
                color: $hot-dark-color;
                font-weight: 900;
            }
        }
    }
}

@media (min-width: 1200px) {
    .about-me-home__container {

        padding: 70px 120px 60px 130px;
    
        .about-me-home__image{
           
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
    
           
            border: 4px solid $cold-dark-color;
            border-radius: 150px;
        }
        .about-me-home__text-container{
            padding-right: 0px;
            padding-left: 50px;
            padding-bottom: 70px;
            width: calc(100vw - 600px);
            h2{
                @include tablet-title;
                color: $cold-dark-color;
                margin: 0px;
                padding: 20px 0;
                mix-blend-mode: multiply;
    
            }
            p{
                @include tablet-text;
    
            }
            .read-more{
                @include tablet-text;
                color: $hot-dark-color;
                font-weight: 900;
            }
        }
    }
}

@media (min-width: 1500px) {
    .about-me-home__container {

        padding: 70px 120px 60px 130px;
    
        .about-me-home__image{
           
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
    
            width: 260px;
            height: 260px;
           
            border: 4px solid $cold-dark-color;
            border-radius: 150px;
        }
        .about-me-home__text-container{
            padding-right: 180px;
            padding-left: 50px;
            padding-bottom: 70px;
            max-width: 1200px;
            h2{
                @include tablet-title;
                color: $cold-dark-color;
                margin: 0px;
                padding: 20px 0;
                mix-blend-mode: multiply;
    
            }
            p{
                @include tablet-text;
    
            }
            .read-more{
                @include tablet-text;
                color: $hot-dark-color;
                font-weight: 900;
            }
        }
    }
}