.testimonies-list__title{
    @include mobile-title;
    color: $hot-dark-color;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 100px;

}
.testimonies-list__container{
    margin: 0px;
    padding: 35px 25px;
    padding-top: 0px;
    margin-bottom: 80px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    

    .card-object{
        background-color: $hot-light-color;
        border-radius: 10px;
        box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);
        margin: 20px 0;
        width: 100%;
      
        display: flex;
        flex-direction: column;
        .testimonies-card__header{
            display: none;
            
            span{
                display: none;}
            
        }
        .testimonies-card__text{
            @include mobile-text;
            padding: 20px;
            white-space: pre-line;
            line-height: 1.5;
            font-weight: 100;
        }
        .testimonies-card__name{
            padding: 0 20px 6px;
            font-size: 15px;
            letter-spacing: 1px;
            font-family: "Lato", sans-serif;
            text-align: end;
            text-transform: uppercase;
        }
        .testimonies-card__date{
            padding: 20px;
            padding-top: 0px;
            font-size: 13px;
            letter-spacing: 1px;
            font-family: "Lato", sans-serif;
            text-align: end;
            // text-transform: uppercase;
        }
        .testimonies-card__footer{
            display: none;
            span{
                display: none;}
        }
    }
}


@media (min-width: 768px) {
    .testimonies-list__title{
        @include tablet-title;
        color: $hot-dark-color;
        margin-bottom: 0px;
    }
    .testimonies-list__container{
        margin: 0px;
        margin-bottom: 120px;
        padding: 0px 60px;
        padding-top: 0px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .card-object{
            background-color: $hot-light-color;
            border-radius: 10px;
            box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);
            margin: 20px 0;
            width: 100%;
            min-width: 80vw;
            display: flex;
            flex-direction: column;

            .testimonies-card__header{
                display: none;
                
                span{
                    display: none;}
                
            }
            .testimonies-card__text{
                @include tablet-text;
                padding: 30px;
                white-space: pre-line;
            }
            .testimonies-card__date{
                padding: 0 30px 30px;
            }
            .testimonies-card__name{
                padding: 0 30px 6px;
                font-size: 18px;
                letter-spacing: 1px;
                font-family: "Lato", sans-serif;
                text-align: end;
                text-transform: uppercase;
            }
            .testimonies-card__footer{
                display: none;
                span{
                    display: none;}
            }
        }
    }
}



@media (min-width: 1200px) {
    .testimonies-list__title{
        @include pc-title;
        color: $hot-dark-color;
        margin-bottom: 0px;
        margin-top: 80px;
        padding-bottom: 60px;
    }
    .testimonies-list__container{
        margin: 0px;
        margin-bottom: 80px;
        padding: 0px 120px;
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        .card-object{
            background-color: $hot-light-color;
            border-radius: 10px;
            box-shadow: 0 20px 60px rgba(0, 0, 0, 0.16);
            margin: 30px 0;
            min-width: 50vw;
            max-width: 1000px;
            display: flex;
            flex-direction: column;

            .testimonies-card__header{
                display: none;
                
                span{
                    display: none;}
                
            }
            .testimonies-card__text{
                @include tablet-text;
                padding: 30px;
                white-space: pre-line;
            }
            .testimonies-card__name{
                padding: 0 30px 6px;
                font-size: 18px;
                letter-spacing: 1px;
                font-family: "Lato", sans-serif;
                text-align: end;
                text-transform: uppercase;
            }
            .testimonies-card__footer{
                display: none;
                span{
                    display: none;}
            }
        }
    }
}